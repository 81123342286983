<template>
  <div>
        <div 
            v-if="needCookieSettings" 
            class="cookie-banner-wrap" id="cookie_popup"     
            :class="{ 'showCookie': needCookieSettings}"
        >            
            <div id="cookieBanner" class="cookie-banner">
                <div class="cookie-banner__inner">
                    <!-- MAIN TEXT -->
                    <div class="cookie-banner__page cookie-banner__page--1" v-if="showPageNum===1">
                        <div class="text">                 
                            <div class="title text-start text-primary">{{mainTitle}}</div>                              
                            <div class="text__inner text-start">
                                {{mainText1}}                                
                                <!-- <div class="link-wrap">
                                    <a :href="baseUrl + '/' + privacyPolicyLink" target="_blank">
                                        {{mainTextLink}}
                                    </a>
                                </div> -->         
                                <!-- {{mainText2}} -->
                            </div>                           
                        </div>                                       
                        <div class="btn-wrap">
                            <button type="button" class="btn-secondary btn" @click="showCookieSettings()">Beállítások</button>                            
                            <button type="button" class="btn-secondary btn" @click="acceptCookies()">Összes elfogadása</button>
                        </div>   
                    </div> 
                    
                    <!-- COOKIE TYPES -->
                    <div class="cookie-banner__page cookie-banner__page--2" v-if="showPageNum===2">  
                        <div class="text">           
                            <div 
                                class="title title--hover d-flex align-items-center" 
                                @click="goToFirstPage"
                            >
                                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.03033 11.5303C6.73744 11.8232 6.26256 11.8232 5.96967 11.5303L0.969669 6.53033C0.676776 6.23744 0.676776 5.76256 0.969669 5.46967L5.96967 0.46967C6.26256 0.176777 6.73744 0.176777 7.03033 0.46967C7.32322 0.762563 7.32322 1.23744 7.03033 1.53033L2.56066 6L7.03033 10.4697C7.32322 10.7626 7.32322 11.2374 7.03033 11.5303Z" fill="#4B4C53"/>
                                </svg>
                                <p class="ms-3 mb-0 text-primary fw-bold">
                                    Beállítások
                                </p>
                                
                            </div>       
                            <div class="cookie-type">
                                <div class="cookie-type__button-row">
                                    <div class="form-check">
                                        <label class="form-check__label text-start" for="necessary-cookie">Alapvető működéshez szükséges sütik</label>                       
                                        <input class="form-check__input" id="necessary-cookie" type="checkbox" v-model="cookies.required" disabled true-value="1" false-value="0">
                                    </div>
                                    <button class="form-check__button" type="button" data-bs-toggle="collapse" href="#necessaryCookieText">
                                        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5303 0.971318C11.8232 1.26421 11.8232 1.73908 11.5303 2.03198L6.53033 7.03198C6.23744 7.32487 5.76256 7.32487 5.46967 7.03198L0.46967 2.03198C0.176777 1.73908 0.176777 1.26421 0.46967 0.971318C0.762563 0.678425 1.23744 0.678425 1.53033 0.971318L6 5.44099L10.4697 0.971318C10.7626 0.678425 11.2374 0.678425 11.5303 0.971318Z" fill="#4B4C53"/>
                                        </svg>
                                    </button>
                                </div>
                                <div class="form-check__content desc collapse" id="necessaryCookieText">
                                    <p 
                                        @click="goToAboutCookies()"
                                        class="cursor-pointer mt-3"
                                    >
                                        <span class="text-primary fw-bold">További információ</span>
                                        <span>&nbsp;</span>
                                        <span>az alapvető cookie-ról.</span>
                                    </p>
                                </div>
                            </div>
                            <div class="cookie-type" v-if="cookies.statistic">
                                <div class="cookie-type__button-row">
                                    <div class="form-check">
                                        <label class="form-check__label" for="statistics-cookie">Statisztikai sütik</label>                       
                                        <input class="form-check__input" id="statistics-cookie" type="checkbox" v-model="cookies.statistic" true-value="1" false-value="0">                                        
                                    </div>
                                    <button class="form-check__button" type="button" data-bs-toggle="collapse" href="#statisticCookieText">
                                        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5303 0.971318C11.8232 1.26421 11.8232 1.73908 11.5303 2.03198L6.53033 7.03198C6.23744 7.32487 5.76256 7.32487 5.46967 7.03198L0.46967 2.03198C0.176777 1.73908 0.176777 1.26421 0.46967 0.971318C0.762563 0.678425 1.23744 0.678425 1.53033 0.971318L6 5.44099L10.4697 0.971318C10.7626 0.678425 11.2374 0.678425 11.5303 0.971318Z" fill="#4B4C53"/>
                                        </svg>
                                    </button>
                                </div>
                                <div class="form-check__content desc collapse" id="statisticCookieText">
                                    <p 
                                        @click="goToAboutCookies()"
                                        class="cursor-pointer mt-3"
                                    >
                                        <span class="text-primary fw-bold">További információ</span>
                                        <span>&nbsp;</span>
                                        <span>a statisztikai cookie-ról.</span>
                                    </p>    
                                </div>
                            </div>
                            <div class="cookie-type" v-if="cookies.marketing">
                                <div class="cookie-type__button-row">
                                    <div class="form-check">
                                        <label class="form-check__label" for="marketing-cookie">Marketing sütik</label>                       
                                        <input class="form-check__input" id="marketing-cookie" v-model="cookies.marketing" type="checkbox" true-value="1" false-value="0">                                        
                                    </div>
                                    <button class="form-check__button" type="button" data-bs-toggle="collapse" href="#marketingCookieText">
                                        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5303 0.971318C11.8232 1.26421 11.8232 1.73908 11.5303 2.03198L6.53033 7.03198C6.23744 7.32487 5.76256 7.32487 5.46967 7.03198L0.46967 2.03198C0.176777 1.73908 0.176777 1.26421 0.46967 0.971318C0.762563 0.678425 1.23744 0.678425 1.53033 0.971318L6 5.44099L10.4697 0.971318C10.7626 0.678425 11.2374 0.678425 11.5303 0.971318Z" fill="#4B4C53"/>
                                        </svg>
                                    </button>
                                </div>
                                <div class="form-check__content desc collapse"  id="marketingCookieText">
                                    <p 
                                        @click="goToAboutCookies()"
                                        class="cursor-pointer mt-3"
                                    >
                                        <span class="text-primary fw-bold">További információ</span>
                                        <span>&nbsp;</span>
                                        <span>a marketing cookie-ról.</span>
                                    </p>    
                                </div>
                            </div> 
                        </div>           
                        <div class="btn-wrap">                            
                            <button type="button" class="btn-secondary btn w-100" @click="goToAboutCookies()">A sütikről</button>
                            <button type="button" class="btn-secondary btn w-100 ms-0 ms-md-4" @click="saveCookieSettings()">Mentés</button>                            
                        </div>                                                
                    </div>    
                    
                    <!-- MORE DETAILS, COOKIE TABLE-->
                    <div class="cookie-banner__page cookie-banner__page--3" v-if="showPageNum===3">
                        <div class="text">
                            <div 
                                class="title title--hover d-flex align-items-center" 
                                @click="showCookieSettings()"
                            >
                                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.03033 11.5303C6.73744 11.8232 6.26256 11.8232 5.96967 11.5303L0.969669 6.53033C0.676776 6.23744 0.676776 5.76256 0.969669 5.46967L5.96967 0.46967C6.26256 0.176777 6.73744 0.176777 7.03033 0.46967C7.32322 0.762563 7.32322 1.23744 7.03033 1.53033L2.56066 6L7.03033 10.4697C7.32322 10.7626 7.32322 11.2374 7.03033 11.5303Z" fill="#4B4C53"/>
                                </svg>
                                <p class="ms-3 mb-0 text-primary">
                                    Cookie tájékoztató
                                </p>
                                
                            </div>
                            <div>
                                <b>A Cookie-k (sütik) használata</b>
                                <br>
                                <br>
                                <b>Mik azok a cookie-k?</b>
                                <br>
                                <br>                                
                                <span>
                                    A cookie-k (sütik) olyan kisméretű szövegfájlok, amelyeket a felhasználó által felkeresett weboldalak helyeznek el a felhasználó számítógépén vagy egyéb eszközén (okostelefon, tablet, stb.). A cookie-kat széles körben alkalmazzák a webhelyek működésének elősegítéséhez, a felhasználói élmény növeléséhez, és ahhoz, hogy a webhelyek tulajdonosai számára adatokat gyűjtsenek. 
                                </span>
                                <br>
                                <br>
                                <b>A cookie-k felhasználása</b>
                                <br>
                                <br>
                                <span>
                                    A cookie-k segítségével gyűjtött adatok alapján elemezzük, hogy a Felhasználó/Látogató miként használja webhelyeinket. A cookie-k által begyűjtött adatokat nem használjuk fel arra, hogy Felhasználót beazonosítsuk, vagy arra, hogy e-mailben vagy postai úton a Felhasználónak reklámanyagokat küldjünk.
                                </span>
                                <br>
                                <br>
                                <span>
                                    Az általunk használt cookie-k tartalmazhatják a Felhasználó online szokásaira és preferenciáira vonatkozó adatokat, és így lehetővé teszik azt, hogy később személyre szabott hirdetéseket jelenítsünk meg a böngészés során.
                                </span>
                                <br>
                                <br>
                                <span>
                                    A cél az, hogy releváns hirdetéseket tegyünk közzé a felhasználóink számára, valamint aktivitásra buzdítsuk őket a jövőbeni Auchan promóciókkal, nyereményjátékokkal kapcsolatban.
                                </span>
                                <br>
                                <br>
                                <b>A cookie-k engedélyezése és letiltása</b>
                                <br>
                                <br>
                                <span>
                                    A Felhasználó, a honlapon tett látogatása során, a weboldal nyitó oldalán felugró képernyőn az <b>ÖSSZES ELFOGADÁSA</b> gombra kattintásával hozzájárulását adja a sütihez. A BEÁLLÍTÁSOK gombra kattintva pedig kiválaszthatja, hogy egy vagy több sütit (cookie) – egy karaktersorozatot tartalmazó kis fájlt – küldjön a weboldal a felhasználó számítógépére, így ezáltal annak böngészője egyedileg azonosítható lesz.
                                </span>
                                <br>
                                <br>
                                <span>
                                    A Felhasználók beállíthatják a készülékeiket úgy, hogy azok az összes cookie-t fogadják vagy az összes cookie-t elutasítsák, illetve arra, hogy értesítsék a felhasználót egy cookie érkezésekor. A cookie-k letiltása esetén az adott Felhasználó számára nem tudunk személyre szabott szolgáltatásokat nyújtani, és emiatt előfordulhat, hogy a Felhasználó nem tudja kihasználni a weboldal minden előnyét.
                                </span>
                                <br>
                                <br>
                                <span>
                                    Ha a Felhasználó egyáltalán nem kíván cookie-kat fogadni, vagy ha bizonyos cookie-kat szeretne letiltani, kérjük igény szerint módosítsa a böngésző beállításait (https://online.auchan.hu/cms/adatvedelem.t.12#9-3-cookie-torles-bongeszok). 
                                </span>
                                <br>
                                <br>
                                <span>
                                    A böngészőprogram menüsorában található „Segítség/Súgó/Beállítások” funkció tájékoztatást ad arról, hogy az egyes böngészőben hogyan lehet letiltani a sütiket, hogyan fogadjon el új sütiket, vagy hogyan kapcsoljon ki egyéb sütiket.
                                </span>
                                <br>
                                <br>
                                <span>
                                    A Google LLC által végzett adatkezelés miatt harmadik országbeli adattovábbítás valósul meg. Az adatok továbbítását a GDPR 45. cikke alapozza meg, mivel a Google LLC az EU-USA adatvédelmi keretrendszer részese.
                                </span>
                                <br/>
                                <br/>
                                <b>A sütikről részletesen:</b>
                                <br>
                                <br>
                                <span>
                                    A sütiket a weboldal látogatása esetén küldi el a weboldal a Felhasználó számítógépére, ezekben csak az adott oldal meglátogatásának tényét és idejét, a felhasználó böngészőjének típusát, az oldalon eltöltött időt és az oldalak közötti váltást tárolja az üzemeltető, más információk és adatok tárolására nem kerül sor.
                                </span>
                                <br>
                                <br>
                                <span>
                                    Az elküldött sütik felhasználása a következő: a külső szolgáltatók (Google) ezen sütik segítségével tárolják, ha a felhasználó korábban már látogatást tett a webhelyen és ez alapján a későbbiekben hirdetéseket jelenítenek meg a felhasználó számára.
                                </span>
                                <br>
                                <br>
                                <b>
                                    Weboldalunkon az alábbi sütiket használjuk:
                                </b>
                                <br>
                                <br>
                                <u>A weboldal működéséhez szükséges sütik</u>
                                <br>
                                <span>
                                    A kifejezetten a weboldal működéséhez szükséges sütik és/vagy technológiák a weboldal megfelelő működéséhez lényegesek. Beállíthatja a böngészőjét, hogy blokkolja a sütiket, viszont ebben az esetben a weboldal bizonyos részei nem fognak helyesen működni.
                                </span>
                                <br>
                                <br>
                                <span>
                                    A sütik megőrzési ideje:
                                </span>
                                <br>
                                <span class="ps-4">
                                    Cookie elfogadás ténye - 1 év
                                </span>
                                <br>
                                <span class="ps-4">
                                    A munkamenettel kapcsolatos cookie - a belépés ideje és hossza - max. 6 óra
                                </span>
                                <br>
                                <br>

                                <div v-if="cookies.statistic">
                                    <u>Statisztikai sütik</u>
                                    <br>
                                    <br>
                                    <span>
                                        Weboldalunk, webanalatikai mérésére a Google Analytics, webelemző szolgáltatását használjuk. A Google Analytics sütik használatával működik, olyan szövegfájlokkal, amelyek a felhasználó számítógépén tárolódnak és lehetővé teszik weblapunk használatának elemzését. A sütik által létrehozott, a weblap felhasználó által történő használatát rögzítő információk rendszerint a Google szerverére kerülnek és ott is tárolódnak.
                                    </span>
                                    <br>
                                    <br>
                                    <span>
                                        A Google ezt az információt arra használja, hogy kiértékelje a weboldal felhasználó által történő használatát és az oldalaktivitásról jelentéseket állítson elő valamint további szolgáltatásokat nyújtson a weboldal működtetőjének a weboldalhasználatával kapcsolatban. A Google Analytics keretén belül a felhasználó böngészőjéről átküldött IP-címet nem kombinálják más Google-adatokkal. A sütik tárolását a felhasználó megakadályozhatja böngészőjének megfelelő beállításával.
                                    </span>
                                    <br>
                                    <br>
                                    <span>
                                        A sütik megőrzési ideje:
                                    </span>
                                    <br>
                                    <span class="ps-4">
                                        Google Analytics cookie a látogatott oldalakról - maximum 365 nap
                                    </span>
                                    <br>
                                    <br>
                                </div>

                                
                                <div v-if="cookies.marketing">
                                    <u>Reklámhoz szükséges sütik</u>
                                    <br>
                                    <br>
                                    <span>
                                        Google AdWords remarketing: A Google ezen sütik segítségével tárolja, ha a felhasználó korábban már látogatást tett a webhelyén, és ennek alapján hirdetéseket jelenítenek meg a felhasználó számára, külső szolgáltatók – közöttük a Google – partnereinek internetes webhelyein.
                                    </span>
                                    <br>
                                    <br>
                                    <span>
                                        A Google által használt sütikről bővebb információt itt olvashat:
                                        <a href="https://policies.google.com/technologies/types?hl=hu" target="_blank">https://policies.google.com/technologies/types?hl=hu</a>
                                    </span>
                                    <br>
                                    <br>
                                    <span>A sütik megőrzési ideje: </span>
                                    <br>
                                    <span class="ps-4">
                                        Google Remarketing cookie a látogatott oldalakról – maximum 365 nap
                                    </span>
                                    <br>
                                    <br>
                                    <span>
                                        Facebook Pixel remarketing: A weboldal ún. Facebook-képpont kódot használ. A Facebookképpont olyan kód, amelynek a segítségével a honlapunkon jelentés készül a konverziókról, célközönségeket állíthatunk össze, és részletes elemzési adatokat kapunk arról, hogy az emberek hogyan használják a honlapunkat. Ezeket az információkat arra használjuk, hogy a Facebook hirdetőcsatornáin relevánsabb hirdetéseket jelenítsünk meg. A Facebook Pixel számára nem adunk át azonosításra alkalmas adatot, viszont amennyiben weboldalunk böngészése közben a Facebook egyéb szolgáltatásaiba be van jelentkezve, a Facebook, mint adatkezelő a Felhasználót és az általa, a mi weboldalunkon végzett tevékenységét is azonosíthatja.
                                    </span>
                                    <br>
                                    <span>
                                        A Facebook által használt sütikről bővebb információt itt olvashat:
                                    </span>
                                    <br>
                                    <a href="https://www.facebook.com/policies/cookies/" target="_blank">https://www.facebook.com/policies/cookies/</a>
                                    <br>
                                    <br>
                                    <span>
                                        A sütik a megőrzési ideje:
                                    </span>
                                    <br>
                                    <span class="ps-4">
                                        Facebook Remarketing Cookie a látogatott oldalakról – maximum 365 nap
                                    </span>
                                    <br>
                                    <br>
                                    <br>                                
                                    <br>
                                </div>                                

                            </div>
                        </div>     
                    </div>
                </div>
            </div>
        </div>
        <!-- EDIT COOKIE POLICY -->        
        <!-- <div class="cookie-policy--edit" v-else>
            <button type="button" class="btn btn-icon" title="Setting cookies" @click="showCookieBlock()">
              Süti beállítás                
            </button>
        </div> -->       
    </div>
</template>

<script>
// import VueCookies from 'vue-cookies'
// Vue.use(VueCookies)
import cookieBannerText from './cookie-banner-text.json'

export default {         
    props:{
        mainTitle:{
            type: String,
            default:cookieBannerText.mainTitle
        },
        mainText1:{
            type: String,
            default: cookieBannerText.mainText.mainText_1
        },
        mainText2:{
            type: String,
            default: cookieBannerText.mainText.mainText_2
        },
        mainTextLink:{
            type: String,
            default: cookieBannerText.mainText.mainText_PPLink
        },
        privacyPolicyLink:{
            type: String,
            default: 'data/Cookie_tajekoztato.pdf'
        }
    },
    data(){
      return{
          needCookieSettings: true,        
          baseUrl: process.env.VUE_APP_BASE_URL,
          cookies:{
              required: '1',
            //   marketing: '1',
              statistic: '1'
          },
          expiredDate: (60 * 60 * 24) * 30 * 6,  
          cookiesNames: {
              required: 'AH23_required',
              marketing: 'AH23_marketing',
              statistic: 'AH23_statistic'
          },
          needCookiesDelete: false,
          showPageNum: 1          
      }
  },  
  mounted(){
    //check if the banner should show
    var _this = this

    if ( parseInt(this.$cookies.get(_this.cookiesNames.required)) === 1 ){          
      _this.needCookieSettings = false        
    }
  }, 
  methods:{
      acceptCookies(){
          //accept all cookies
          let _this = this            
          
          //if we change the cookies settings - need delete all cookies before
          if (_this.needCookiesDelete){
              _this.delAllCookies()
          }
          
          // required_cookie, marketing_cookie, statistic_cookie       
          this.$cookies.set( _this.cookiesNames.required, 1, this.expiredDate);

          if (_this.cookies.marketing){
            this.$cookies.set(_this.cookiesNames.marketing, 1, this.expiredDate)
          }
          
          if (_this.cookies.statistic){
            this.$cookies.set(_this.cookiesNames.statistic, 1, this.expiredDate);
          }          

          this.needCookieSettings = false                
      },
      goToFirstPage(){        
        this.showPageNum = 1
      },
      showCookieSettings(){        
        this.showPageNum = 2
      },
      goToAboutCookies(){
          this.showPageNum = 3
      },
      saveCookieSettings(){
          let _this = this            

          //if we change the cookies settings - need delete all cookies before
          if (_this.needCookiesDelete){
              _this.delAllCookies()
          }

          //required cookies
          this.$cookies.set(_this.cookiesNames.required, 1, _this.expiredDate);

          //marketing cookies
          if ( _this.cookies.marketing && parseInt( _this.cookies.marketing) === 1 ){
              this.$cookies.set(_this.cookiesNames.marketing, 1, _this.expiredDate);
          } 

          //statistic cookies
          if ( this.cookies.statistic && parseInt(this.cookies.statistic) === 1 ){
              this.$cookies.set(_this.cookiesNames.statistic, 1, _this.expiredDate);
          }

          this.needCookieSettings = false        
          
      },
      showCookieBlock(){
          let _this = this

          this.needCookieSettings = true
          this.changeCookies = true            
          this.needCookiesDelete = true

          this.cookies.required = this.$cookies.get(_this.cookiesNames.required);
        //   this.cookies.marketing = this.$cookies.get(_this.cookiesNames.marketing);
          this.cookies.statistic = this.$cookies.get(_this.cookiesNames.statistic);

      },
      delAllCookies(){
          let _this = this            
          var allCookies = _this.$cookies.keys()            

          allCookies.forEach( cookie => {
            _this.$cookies.remove(cookie)
          })
      }                
  }
}
</script>

<style scoped lang="scss">
    
    #cookie_popup{
        position: fixed;
        top:0;
        left:0;
        bottom:0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);        
        display: none;
        font-size: 14px;
        z-index: 10;

        &.showCookie{
            display: flex!important;
            align-items: center;
            justify-content: center;
        }

        .cookie-banner{
            background: white;
            color:var(--bs-dark);
            padding: 30px 25px;
            width: 546px;
            max-height: 528px;
            overflow: auto;
            border-radius: 10px;
        }
        .title{            
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            font-size: 20px;
            line-height: 20px;
            margin-bottom: 15px;    
            position: relative;  
            
            
            &--hover{
                cursor: pointer;   
            }
        }              

        .btn-wrap{
            display: flex;
            align-items: center;    
            justify-content: space-between;
            margin-top:20px;

            .btn{
                width: 50%;

                &:last-child{
                    margin-left: 20px;
                }
            }
        }

        .btn{
            padding:0 20px!important;
        }                

        >* {
            scrollbar-width: thin;
            scrollbar-color: rgba(231, 68, 74, .25) white;
        }        
        
        :-webkit-scrollbar {
            width: 14px;
        }

        /* 4px scroll thumb. 10px margin from right margin */
        ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 14px 14px transparent;
            border: solid 4px transparent;
        }

        ::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 14px 14px #E7444A;
            border: solid 4px transparent;
            border-radius: 14px;
        }

        ::-webkit-scrollbar-button {
            display: none;
        }

                    
        .subtitle{
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            margin-bottom: 10px;
        }
        ul{
            padding-left:20px;
            list-style-type: disc;

            &:not(.list-type){
                list-style-type: none;                

                li{
                    display: flex;      
                }
            }

            li img{
                margin-right: 8px;
                color:#E7444A;
                width: 12px;
                flex-shrink: 0;
                flex-grow: 0;    
                display: inline-flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
                flex-grow: 0;
            }
            
        }
        .cookie-type{
            padding: 5px 0;

            &__button-row{
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
        .form-check{
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: normal;
            padding-left: 0;
            &__label{
                order: 1;
                font-size: 14px;
                padding-left: 0.5rem;
                text-transform: none;
                color:#141918;
                margin-bottom: 0;                
            }
            &__button{
                border: none;
                outline: 0;
                background-color: transparent;
            }
            &__input{
                height: 25px;
                width: 25px;
            }
            &__content{
                text-align: left;
            }
        }

        @media screen and (max-width: 576px){
            .cookie-banner{
                width: 90vw;
                max-height: 90vh;
                overflow: auto;
                max-height: 480px;
            }
            .btn-wrap{
                flex-wrap: wrap;
                .btn{
                    width: 100%;
                    &:last-child{
                        margin-left: 0;
                    }
                }
            }
            .btn{
                width: 100%;
                margin-bottom:10px;

                &:last-child{
                    margin-bottom: 0;
                }
            }        
        }

        input[type=checkbox] {
            accent-color: white;
        }

        .cursor-pointer{
            cursor: pointer;
        }
    }                        

</style>