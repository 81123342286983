import { required, email, confirmed, length, integer, size, ext, regex, alpha_num } from "vee-validate/dist/rules"
import { extend } from "vee-validate"
import { setInteractionMode } from 'vee-validate'

setInteractionMode('lazy')

extend("email", {
    ...email,
    message: "A megadott e-mail cím érvénytelen, a formázás ellenőrzése szükséges (pl. email@domain.com)."
  })

extend("required", {
  ...required,
  message: "Kötelező mező"
})

extend("alpha_num", {
  ...alpha_num,
  message: "Nem tartalmazhat speciális karaktert"
})

extend("confirmed", {
  ...confirmed,
  message: "Nem egyező értékek"
})

extend("integer", {
  ...integer,
  message: "Csak számok adhatók meg"
})


extend("length", {
  ...length,
  message: "Nem megfelelő hossz"
})

extend("min", {  
  validate(value, args) {
    return value.length >= args.length
  },
  params: ["length"],
  message: "Legalább {length} karaktert kell tartalmaznia"
})

extend("max", {  
  validate(value, args) {
    return value.length <= args.length
  },
  params: ["length"],
  message: "Legfeljebb {length} karaktert tartalmazhat"
})

extend("size", {
  ...size,
  message: "Max 5MB lehet a fájl mérete"
})

extend("ext", {
  ...ext,
  message: "Csak png vagy jpeg formátum tölthető fel"
})


extend("regex", {
  ...regex,
  message: "Nem megfelelő karakterek"
})

extend("acceptConditions", {
  validate(value) {
    if (parseInt(value) === 1) {
      return true;
    } else {
      return false;
    }
  },
  message: "Kötelező mező"
});

extend("customRegex", {
  validate(value) {
    var reg = new RegExp('[0-9]');
    console.log(reg.test(value))
    if (reg.test(value)){
      return false
    } else {
      return true
    }    
  },
  message: "Nem megfelelő karakterek"
});

extend("customPhone", {
  //budapesti 1 és 7 db számjegy,  --> 1-345-4455 : 10db 
// vidék 2db körzet és 6 db számjegy --> 45-465-588 : 10db
// mobilok, 20-30-50- 31-70-es lehet csak 7db számjegy kell, hogy utána szerepeljen  - 30-456-5566 : 11db
  validate(value) {
    var reg = /^(20|30|50|31|70)$/;
    //var reg2 = /^(1)$/;
    console.log('customPhone',value.length);
    if(value.length == 11){
      //mobil
      if(reg.test(value.slice(0,2))){
        return true
      }
    }else if(value.length == 10){      
        //Bp        
        //if(reg2.test(value.slice(0,1))){
          return true
        //}
    }else{
      return false
    }
  },
  message: "Nem megfelelő körzetszám"
});


extend("customPsw", {
  validate(value) { 
    const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d]).{8,}$/;
    //const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    console.log('customPsw', reg.test(value), value)
    return reg.test(value) 
  },
  message: "A jelszó formátuma nem megfelelő"
});