export const api_conf = {
    branding: "Auchan", 
    apiDomain: process.env.VUE_APP_BASE_URL, // not tail per sign
    apiDir : "api" ,// not tail per sign
    apiName : "", // not tail per sign
    uploadFolder : "uploads", // not tail per sign
    adminName: "Admin felület",
    websiteName: "Website name",
    invalidTokenCheck: true,
    invalidTokenRedirectPath: "/",
    invalidUserRedirect: "/",
    successLoginPath: "/",
    enabledUserTypes: ['visitor'],
    emailAddress: "toffifee@jatek.a4c.hu",
    needLanguage: true,
    defaultLanguageId: '1',
    defLanguages:[
        {
            id: 1,
            code: "hu",
            name: "Magyar"            
        }        
    ],
    // maxFullPcs:30, 
    // maxOneDayPcs:3,
    // maxOnePurchaseDayPcs:2
}
