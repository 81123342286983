export default {   
    computed:{

    },
    methods: {  
        buildApiUrl(url){            
            return this.$api_conf.apiDomain +"/"+ this.$api_conf.apiDir + "/"+ url;            
        },     
        get: function (url, config,myvars) {
            ////console.log(JSON.stringify(config));
            var _this = this;
            //let headers = null

            if (!config) {
                config = {};
            }
            if (!config.params) {
                config.params = {};
            } else {
                config.params = _this.processParamsData(config.params);
            }
            if ( config.params.language_id == undefined && _this.$store.state.language_id)
            {
                config.params.language_id = _this.$store.state.language_id;
            }
            // if (this.$store.state.token && config.params.token == undefined) {
            //     config.params.token = this.$store.state.token;
            // }

            if (this.$store.state.token && config.params.token == undefined) {
                //config.params.token = this.$store.state.token;
                //config.headers = { 'Authorization': `bearer ${this.$store.state.token}` }
                config.headers = {
                    // 'Content-Type': 'application/json',
                    // 'Authorization': `Bearer ${this.$store.state.token}`,
                    'token': `${this.$store.state.token}` 
                };
                //config.headers = { 'token': `${this.$store.state.token}` }
            }

            return new Promise( (resolve,reject) => {
                _this.$axios.get(
                    _this.buildApiUrl(url), 
                    config                                    
                ).then((result) => {
                    if (result.data.status == false)
                        reject(result,myvars);
                    else
                        resolve(result,myvars);
                }).catch(error => {
                    if (_this.$api_conf.invalidTokenCheck)
                    {
                        if (this.errorHandling(_this,error))
                            reject(error,myvars);
                    }
                    else
                        reject(error,myvars)
                })
            })
        },

        delete: function (url, config,myvars) {
            var _this = this;
           
            if (!config) {
                config = {};
            }
            if (!config.params) {
                config.params = {};
            } else {
                config.params = _this.processParamsData(config.params);
            }
            if ( config.params.language_id == undefined && _this.$store.state.language_id)
            {
                config.params.language_id = _this.$store.state.language_id;
            }
            if (this.$store.state.token && config.params.token == undefined) {
                config.params.token = this.$store.state.token;
            }
            return new Promise( (resolve,reject) => {
                _this.$axios.delete(_this.buildApiUrl(url), config).then((result) => {
                    if (result.data.status == false)
                        reject(this.generateAnswer(result,myvars));
                    else
                        resolve(this.generateAnswer(result,myvars));
                }).catch(error => {
                    if (_this.$api_conf.invalidTokenCheck)
                    {
                        if (this.errorHandling(_this,error))
                            reject(this.generateAnswer(error,myvars));
                    }
                    else
                        reject(this.generateAnswer(error,myvars))
                })
            })
           
        },

        post: function (url, data, config,myvars) {
            var _this = this;

           
            if (!config) {
                config = {};
            }

            if (!config.params) {
                config.params = {};
            } else {
                config.params = _this.processParamsData(config.params);
            }

             if ( config.params.language_id == undefined && this.$api_conf.needLanguage)
            {
                config.params.language_id = _this.$store.state.language_id;
            }

            if (this.$store.state.token && config.params.token == undefined && url != 'promologin' && url != 'register' && url != 'forgottenpassword' && url != 'forgottenpassword/sendmail' && url != 'message') {
                // console.log("token küldés van", url)
                // config.params.token = this.$store.state.token;
                config.headers = {                    
                    'token': `${this.$store.state.token}` 
                };
            }

            config.onUploadProgress = function (progressEvent) {
                let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                return percentCompleted
            }
            return new Promise( (resolve,reject) => {
                _this.$axios.post(_this.buildApiUrl(url),data, config).then((result) => {
                    if (result.data.status == false)
                        reject(this.generateAnswer(result,myvars));
                    else
                        resolve(this.generateAnswer(result,myvars));
                }).catch(error => {
                    if (_this.$api_conf.invalidTokenCheck)
                    {
                        if (this.errorHandling(_this,error))
                            reject(this.generateAnswer(error,myvars));
                    }
                    else
                        reject(this.generateAnswer(error,myvars))
                })
            })
            
        },

        put: function (url, data, config,myvars) {
            var _this = this;

            
            
            if (!config) {
                config = {};
            }

            if (!config.params) {
                config.params = {};
            } else {
                config.params = _this.processParamsData(config.params);
            }

             if ( config.params.language_id == undefined && this.$api_conf.needLanguage)
            {
                config.params.language_id = _this.$store.state.language_id;
            }

            if (this.$store.state.token && config.params.token == undefined && url != 'promologin') {
                config.params.token = this.$store.state.token;
            }

            config.onUploadProgress = function (progressEvent) {
                let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                return percentCompleted
            }
            return new Promise( (resolve,reject) => {
                _this.$axios.put(_this.buildApiUrl(url),data, config).then((result) => {
                    if (result.data.status == false)
                        reject(this.generateAnswer(result,myvars));
                    else
                        resolve(this.generateAnswer(result,myvars));
                }).catch(error => {
                    if (_this.$api_conf.invalidTokenCheck)
                    {
                        if (this.errorHandling(_this,error))
                            reject(this.generateAnswer(error,myvars));
                    }
                    else
                        reject(this.generateAnswer(error,myvars))
                })
            })
            
        },
        generateAnswer(answer,myvars){
            if (myvars == undefined)
                return answer;
            else
                return {
                    result: answer,
                    vars: myvars
                }
        },
        redirect:  function(route)
        {
          if (this.$router.history.current.fullPath == route)
          {
            return;
          }
          this.$router.push(route);
        },
        checkLoggedIn: function(){
            var _this = this;
            _this.$store.state.token = (sessionStorage.getItem('token') != false) ? sessionStorage.getItem('token') : false;
            _this.$store.state.languageId = (sessionStorage.getItem('languageId') != false) ? sessionStorage.getItem('languageId') : _this.$api_conf.defaultLanguageId;
            return new Promise( (resolve,reject) => {
                _this.get('checktoken', {}).then(resp=>{
                    _this.$store.state.user = resp.data.user;
                    
                    // console.log('usertype', this.$api_conf.enabledUserTypes,_this.$store.state.user.user_type)

                    // if (_this.$api_conf.enabledUserTypes.indexOf(_this.$store.state.user.user_type ) < 0)
                    // {
                    //     console.log("invalid checktoken")
                    //     reject()
                    //     //_this.redirect(  _this.$api_conf.invalidUserRedirect)
                    // }
                    // else
                        resolve(resp);
                }).catch(error=>{
                    //_this.redirect( _this.$api_conf.invalidUserRedirect)
                    console.log("invalid checktoken 2")
                    reject(error);
                })
            })
          },
        getLanguages(){
            if (Object.keys(this.$store.state.languages).length == 0)
            {
                return new Promise((rs,rj)=>{
                    this.get('language',{}).then((resp)=>{
                        resp.data.language.forEach((e)=>{
                            this.$store.state.languages[e.id]=e;
                        })
                        rs(this.$store.state.languages);
                    }).catch((e)=>{
                        rj(e.response)
                    })


                })
            }
            // return new Promise((rs,rj)=>{
            //     rs(this.$store.state.languages)
            // })
          },
        getConfigFile(overwrite){
            if (!overwrite )
                overwrite = false;
            var _this = this;
            return new Promise( (resolve,reject) => {
            _this.get('config', {}).then(resp=>{
                if (!_this.$store.state.config || overwrite )
                _this.$store.state.config = resp.data.config;
               
                    resolve(resp);
                }).catch(error=>{
                    reject(error);
                })
            })
            
        },
        successHandeling: function (_this, myid, result, success, error_func) {

            if (result.data.status == false) {
                if (error_func) {
                    error_func(result);
                }
                _this.$store.state.apiCalls[myid].status = 'error'
                _this.$forceUpdate();
                return;
            }
            else {
                if (success) {
                    success(result);
                }
                _this.$store.state.apiCalls[myid].status = 'success';
                _this.$forceUpdate();
                return;
            }
        },
        errorHandling: function (_this, error) {
            if (error.response != undefined)
            {
                if (error.response.data.error.token == 'Token is invalid or expired!') {
                    sessionStorage.removeItem('token');
                    this.$store.state.token = undefined;
                    this.$router.push( _this.$api_conf.invalidTokenRedirectPath);
                    return false;
                }
            }
            return true;  
        },
        processParamsData: function (obj) {
            var ret = {};
            for (var i in obj) {
                if (Array.isArray(obj[i]) || obj === Object(obj))
                    Object.assign(ret, this.processParamsDataRecoursive(i, (obj[i])));
                else
                    ret[i] = obj[i]

            }
            return ret;
        },
        processParamsDataRecoursive: function (prefix, obj) {
            var ret = {};
            if (Array.isArray(obj[i]) || obj === Object(obj)) {
                for (var i in obj) {
                    Object.assign(ret, this.processParamsDataRecoursive(prefix + "[" + i + "]", (obj[i])));
                }
            }
            else {
                ret[prefix] = obj;
            }
            return ret;
        },
        login(email,pass, captcha, id){
            var _this = this;
            return new Promise( (resolve,reject) => {
                _this.post(
                    "promologin",                    
                    {
                        email: email,
                        password: pass,
                        captcha: captcha,
                        captcha_id: id
                    },
                {}).then((result) => {
                    _this.$store.state.token = result.data.token;
                    sessionStorage.setItem("token", result.data.token);
                    //////////console.log('logged in')
                    _this.$store.state.user = result.data.user;
                    resolve(result);
                }).catch(error=>{
                    reject(error);
                })
            })
        },
        logout(){
            sessionStorage.removeItem('token');

            this.$store.state.token = false;
            this.$store.state.user = false;
            //this.$router.push(this.$api_conf.invalidTokenRedirectPath);

        }
    }
}